import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper" id="Testimonials">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
        <p className="primary-text">
          Customers are our capital. Customer satisfaction is our first
          priority. ਗ੍ਰਾਹਕ ਹੀ ਸਾਡੀ ਪੂਂਜੀ ਹਨ । ਗ੍ਰਾਹਕ ਦੀ ਸੰਤੁਸਟੀ ਹੀ ਸਾਡੀ ਪਹਿਲੀ
          ਤਰਜੀਹ ਹੈ ।
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" />
        <p>
          I was looking for such an app for a long time to help me clear my
          Punjab State Teacher Eligibility Test. This app proved to be very
          helpful for me. Very easy and reliable. Thanks to Sondh Web Creator.
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>Amanpreet Kaur</h2>
      </div>
    </div>
  );
};

export default Testimonial;
