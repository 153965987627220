import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Privacy Policy</h1>
      <p className={styles.effectiveDate}>
        <strong>Effective Date:</strong> 16-01-2024
      </p>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>1. Information We Do Not Collect</h2>
        <p className={styles.text}>
          We do not collect, store, or process any personal data through the
          App. The App serves as a WebView to display the content of a website
          (https://www.sondhwebcreators.com/) provided by our client. Any data
          collection and processing are governed by the privacy policy of the
          Website.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>2. Third-Party Content</h2>
        <p className={styles.text}>
          The App provides access to the Website through a WebView. We are not
          responsible for the content, functionality, or data practices of the
          Website. Please review the Website’s privacy policy to understand how
          your data is handled.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>3. Permissions</h2>
        <p className={styles.text}>
          The App may request certain device permissions (e.g., internet access)
          solely to provide functionality for accessing the Website. We do not
          use these permissions to collect or process any personal data.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>4. Third-Party Links</h2>
        <p className={styles.text}>
          The Website accessed through the App may contain links to third-party
          websites or services. We are not responsible for the privacy practices
          or content of these third-party websites. We encourage you to review
          the privacy policies of those third parties.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>5. Security</h2>
        <p className={styles.text}>
          While we do not collect or store any data, we strive to ensure that
          the App operates securely. However, we cannot guarantee the security
          of the Website or third-party services accessed through the App.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>6. Changes to This Privacy Policy</h2>
        <p className={styles.text}>
          We may update this Privacy Policy from time to time. Any changes will
          be reflected with a revised "Effective Date." Continued use of the App
          after any modifications indicates your acceptance of the updated
          Privacy Policy.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subHeader}>7. Contact Us</h2>
        <p className={styles.text}>
          If you have any questions or concerns about this Privacy Policy or the
          App, please contact us at:
        </p>
        <address className={styles.contactDetails}>
          Sondh Web Creators <br />
          sondhwebcreators@gmail.com <br />
          Amritsar, Punjab, India
        </address>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
